<template>
  <el-row class="settings-items-table">
    <el-row class="ta-r">
      <el-button type="success" class="add-button" @click="onAdd">
        +
      </el-button>
    </el-row>
    <el-table
      v-loading="loading"
      :data="items"
      empty-text="Нет данных"
      stripe
      :class="['mt-20', { 'at-empty': !items.length }]"
      style="width: 100%"
    >
      <el-table-column
        v-for="el in tableFields"
        :key="el.prop"
        :label="el.label"
        :prop="el.prop"
        :width="el.width + 'px'"
        :min-width="el.minWidth ? `${el.minWidth}px` : ''"
      >
        <template slot-scope="props">
          <TableCell
            :row="props.row"
            :data="props.row[el.prop]"
            :prop-key="el.prop"
          />
        </template>
      </el-table-column>
      <el-table-column width="36px">
        <template slot-scope="scope">
          <div class="edit-icon" @click="onEdit(scope.$index, scope.row)" />
        </template>
      </el-table-column>
    </el-table>
    <i-pagination :params="pagination" @changed="onChangePage" />
  </el-row>
</template>

<script>
import { mapMutations } from 'vuex'
import TableCell from '@/components/Ui/TableCell'
import IPagination from '@/components/Ui/Pagination'
import { getTableFields } from '../constants'

export default {
  name: 'SettingsItemsTable',
  components: { TableCell, IPagination },
  props: {
    type: {
      type: String,
      default: 'products'
    },
    items: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tableFields() {
      return getTableFields(this.type)
    }
  },
  methods: {
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    onEdit(index, row) {
      this.setDialog({
        name: `edit-${this.type}-modal`,
        visible: true,
        data: row
      })
    },
    onAdd() {
      this.setDialog({
        name: `edit-${this.type}-modal`,
        visible: true
      })
    },
    onChangePage(page) {
      this.$emit('fetch', `${this.type}Get`, { page })
    }
  }
}
</script>

<style lang="sass">
.settings-items-table
  .at-empty:before
    background-color: unset
  .add-button
    width: 45px
    height: 33px
    border-radius: 10px
    padding: 0
    font-weight: bold
    font-size: 30px
    line-height: 1
</style>

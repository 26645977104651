<template>
  <page-wrapper
    title="Настройки компании"
    link="Выход"
    class="settings-page"
    @click="logout"
  >
    <span slot="link" class="sp-link" @click="toProfile">
      <span class="bb cp">К профилю</span>
    </span>
    <el-row>
      <el-col :sm="8">
        <el-col :class="['fs-20 fw-l black', { 'fw-b': type === 'products' }]">
          Культуры ({{ productsPagination.totalCount || 0 }})
        </el-col>
        <el-col class="t-grey fs-20 mt-10 mb-40 fw-l">
          <span class="bb cp" @click="type = 'products'">Редактировать</span>
        </el-col>
      </el-col>
      <el-col :sm="8">
        <el-col :class="['fs-20 fw-l black', { 'fw-b': type === 'consignee' }]">
          Грузополучатели ({{ consigneePagination.totalCount || 0 }})
        </el-col>
        <el-col class="t-grey fs-20 mt-10 mb-40 fw-l">
          <span class="bb cp" @click="type = 'consignee'">Редактировать</span>
        </el-col>
      </el-col>
    </el-row>
    <settings-items-table
      :type="type"
      :items="items"
      :pagination="pagination"
      :loading="loading"
      @fetch="onFetch"
    />
    <edit-products-modal />
    <edit-consignee-modal />
  </page-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PageWrapper from '@/components/Wrappers/PageWrapper'
import EditProductsModal from '@/components/Modals/EditProductsModal'
import EditConsigneeModal from '@/components/Modals/EditConsigneeModal'
import SettingsItemsTable from './components/SettingsItemsTable'

export default {
  name: 'Settings',
  components: {
    EditConsigneeModal,
    PageWrapper,
    EditProductsModal,
    SettingsItemsTable
  },
  data() {
    return {
      type: 'products'
    }
  },
  created() {
    this.productsGet({ ['per-page']: 999999 })
    this.consigneeGet({ ['per-page']: 999999 })
  },
  computed: {
    ...mapGetters({
      productsItems: 'products/getList',
      productsPagination: 'products/getPagination',
      productsLoading: 'products/getLoading',
      consigneeItems: 'consignees/getList',
      consigneePagination: 'consignees/getPagination',
      consigneeLoading: 'consignees/getLoading'
    }),
    items() {
      return this[`${this.type}Items`]
    },
    pagination() {
      return this[`${this.type}Pagination`]
    },
    loading() {
      return this[`${this.type}Loading`]()
    }
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      productsFETCH: 'products/FETCH',
      consigneeFETCH: 'consignees/FETCH'
    }),
    productsGet(settings) {
      this.productsFETCH(settings)
    },
    consigneeGet(settings) {
      this.consigneeFETCH(settings)
    },
    onFetch(funcName, settings) {
      this[funcName](settings)
    },
    toProfile() {
      this.$router.push({ name: 'Profile' })
    }
  }
}
</script>

<style lang="sass">
.settings-page
  .sp-link
    position: relative
    margin-right: 40px
    padding-left: 35px
    &:before
      content: ''
      position: absolute
      background: url('~@/assets/imgs/icons/user-icon.png') no-repeat
      background-size: contain
      left: 0
      top: 0
      width: 21px
      height: 23px
  .custom-title
    font-size: 20px
    line-height: 23px
    color: $blackColor
    font-weight: normal
</style>

export const getTableFields = (type = '') => {
  return tables[`${type}Fields`]
}

const tables = {
  productsFields: [
    { prop: 'id', label: 'ID', width: 80 },
    { prop: 'full_name', label: 'НАИМЕНОВАНИЕ КУЛЬТУРЫ' }
  ],
  consigneeFields: [
    { prop: 'id', label: 'ID', width: 80 },
    { prop: 'name', label: 'ГРУЗООТПРАВИТЕЛЬ' },
    { prop: 'consignee_address', label: 'АДРЕС ГРУЗООТПРАВИТЕЛЯ' }
  ]
}
